import React from "react";
import './Dummy.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

function Dummy(props) {
    return (
        <Container className='dummy-page'>
            <Row>
                <Col>
                    <div className='dummy-buttons'>
                        { (props.loggedIn === true) ? 
                            (<>
                                <Link to='/crm'>
                                    <Button variant='primary' size='lg' className='dummy-buttons__button'>Dashboard</Button>
                                </Link>
                            </>)
                            :
                            (<>
                                <div className='dummy__home-page'>
                                    <h1 className='home-page__title'>Welcome to CRM</h1>
                                    {/* <div className='home-page__under-title'>by Inspectron</div> */}
                                    <Link to='/login'>
                                        <Button variant='primary' size='lg' className='dummy-buttons__button'>Sign In</Button>
                                    </Link>
                                    <Link to='/register'>
                                        <Button variant='primary' size='lg' className='dummy-buttons__button'>Register</Button>
                                    </Link>
                                </div>
                                
                            </>)
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Dummy;