import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './UserPanel.css';
import pluralize from "pluralize";

function UserPanel(props) {

    function getProperName(table) {
        let name = pluralize.singular((table[0].toUpperCase() + table.slice(1).replace(/[-_][a-z]/, a => {
            return " " + a[1].toUpperCase();
        })));
        // pluralize.singular((nonEditedName[0].toUpperCase() + nonEditedName.slice(1).replace(/[-_][a-z]/, a => {
        //     return a[1].toUpperCase();})));
        return name;
    }
    
    return (
        <div className='default-sidebar'>
            <Container className='sidebar-container'>
                <Row>
                    <Col className='panel-body side-navbar justify-content-between'>
                        {(props.loggedIn === true && props.tableMeta) ?
                        
                        ( <>
                                <div className='panel-body-content-wrapper'>
                                    <ul className='panel-body__models-list d-flex flex-column'>
                                        <li className='model-list__title'>Tables:</li>
                                        {props.tableMeta.map((table, index) => {
                                            return(
                                            <li key={index}>
                                                <Link to={'/crm/' + table.table +''} className='models-list__model'>{getProperName(table.table)}</Link>
                                            </li>)})
                                        }
                                    </ul>
                                </div>
                                <div className='panel-body__buttons d-flex'>
                                    {/* <Link to='/'>
                                        <Button className='buttons__button'>Home</Button>
                                    </Link> */}
                                    <Link 
                                        className='logout-button'
                                        to='/' 
                                        onClick={props.setLogout}
                                    >
                                        <Button variant='secondary' className='btn btn-shadow btn-block'>Log Out</Button>
                                    </Link>
                                    
                                </div>
                            </>)
                        

                        :

                            (<>
                                <div className='panel-body__buttons d-flex justify-content-center'>
                                    <Link to='/login'>
                                        <Button variant='secondary' className='btn btn-secondary buttons__button'>Login</Button>
                                    </Link>
                                    <Link to='/register'>
                                        <Button variant='secondary' className='btn btn-secondary buttons__button'>Register</Button>
                                    </Link>
                                </div>
                                <div className='text-center'>
                                    by Inspectron
                                </div>
                            </>)
                        }
                    </Col>
                </Row>
            </Container>
        </div>
        
        
    )
}

export default UserPanel;