import React, {useState, useEffect, useMemo} from "react";
import Dummy from '../DummyPage/Dummy';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LoginForm from "../EntryForms/LoginForm";
import RegistryForm from "../EntryForms/RegistryForm";
import './App.css';
// import '../../Styles/bootstrap.css';
import '../../Styles/elisyam-15.css';
import { Container, Row, Col } from 'react-bootstrap';
import UserPanel from "../UserPanel/UserPanel";
import TopPanel from "../TopPanel/TopPanel";

// import ManageRelations from "../ManageRelations/ManageRelations";
import CrmMainPage from "../CrmMainPage/CrmMainPage";

function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedUserData, setLoggedUserData] = useState({});
  const [tableMeta, setTableMeta] = useState();
  // const [userRole, setUserRole] = useState();

  const [url, setUrl] = useState('');

  useEffect(() => {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      setUrl('https://spacehamster.ch');
    } else {
      setUrl('');
    }
  }, []);

  const urlMemo = useMemo(()=> {return url}, [url]);

  useEffect(() => {
    localStorage.token ? setLoggedIn(true) : setLoggedIn(false);
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

    var graphql = JSON.stringify({
    query: "{tableMeta}",
    variables: {}
    });
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
    };

    if (loggedIn === true) {
      fetch(urlMemo +"/graphql", requestOptions)
    .then(response => response.json())
    .then(result => setTableMeta(result.data.tableMeta))
    .catch(error => console.log('error', error));
    }
  }, [loggedIn, urlMemo]);

  // console.log(tableMeta);

  const setLogout = () => {
    setLoggedIn(false);
    localStorage.clear();
  };

  return (
    <div className="app">
      
      <Router>
        <TopPanel
          loggedIn={loggedIn}
          loggedUserData={loggedUserData}
        />
        <div className='d-flex main-content-section'>
          <UserPanel 
            loggedIn={loggedIn} 
            setLogout={setLogout}
            tableMeta={tableMeta}
          />
          <Container fluid className='content-section-body'>
            <Row>
              <Col>
                <Switch>
                  <Route exact path='/'>
                    <Dummy loggedIn={loggedIn} />
                  </Route>
                  <Route
                      path='/register'
                  >
                    <RegistryForm url={url}/>
                  </Route>
                  <Route path='/login'>
                    <LoginForm 
                      setLoggedIn={setLoggedIn}
                      setLoggedUserData={setLoggedUserData}
                      setTableMeta={setTableMeta}
                      url={url}
                    />
                  </Route>
                  <Route path='/crm'>
                    <CrmMainPage 
                      tableMeta={tableMeta}
                      url={url}
                    />
                  </Route>

                  {/* nest it inside generated tables and add router

                  <Route path='/add-user'>
                    <CreateUser
                      tableMeta={tableMeta}
                    />
                  </Route>
                  <Route path='/add-room'>
                    <CreateRoom
                      tableMeta={tableMeta}
                    />
                  </Route> */}



                  {/* <Route 
                    path='/manage-user-groups'
                  >
                    <ManageRelations tableMeta={tableMeta}/>
                  </Route> */}
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
        
      
      </Router>  
    </div>
    
  );
}

export default App;