import React from 'react';
import { ListGroup } from 'react-bootstrap';
import MainTableRow from './MainTableRow';


const MainTableBody = (props) => {
    return (
        <ListGroup>
            {props.data.map((data, index) => (
                <MainTableRow 
                    tableName={props.tableName}
                    key={index} 
                    data={data}
                    setDataUpdated={props.setDataUpdated}
                    url={props.url}
                    handleShow={props.handleShow}
                />
            ))}
        </ListGroup>
    )
}

export default MainTableBody;