import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { ModularInput } from "./ModularInput";
import "./ModularModal.css";

export const MapModal = (props) => {
    const [value, setValue] = useState(props.val) 
    const [newKey, setNewKey] = useState("");
    const setKeyValue = (key, newVal) => setValue({...value, [key]: newVal })
    // console.log({value})
    return (
        <Modal show={props.show} onHide={props.handleShow} centered className='modular-edit-modal'>
            <Modal.Header>
                <Modal.Title>{props.inputKey}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                <Form.Group className='create-user-form-body__input-group mb-3' controlId="formBasicEmail" key="new-field">

                    {
                        value && Object.entries(value).filter(([_, a]) => !!a).map(([key, val, index]) =>
                            <div className="map-item" key={index}>
                                <ModularInput
                                    inputKey={key}
                                    key={key}
                                    val={value[key]}
                                    type={props.keyType}
                                    setKeyValue={setKeyValue}
                                />
                                <Button 
                                    onClick={() => {
                                        // console.log(key)
                                        setKeyValue(key, undefined)
                                    }}
                                >RemoveField </Button>
                            </div>)
                    }

                    <Form.Label className='input-group__field-title'>
                        New field
                    </Form.Label>
                    <Form.Control
                            name="new field"
                            className='input-group__input-field'
                            type='text'
                            value={newKey}
                            placeholder={'new field'}
                            onChange={e => setNewKey(e.target.value)}
                        />
                        <Button 
                            onClick={() => {
                                setKeyValue(newKey, {})
                                setNewKey("");
                            }}
                        >Add Field </Button> 
                </Form.Group>
                    <div className='d-flex form__action-btns'> 
                        <Button 
                            onClick={() => {
                                props.deleteCb(value)
                                props.handleShow()
                            }}
                        >Delete </Button> 
                    </div>
                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className='form__btn-back' onClick={props.handleShow}>Cancel</Button>
                <Button 
                    variant="primary" 
                    className='form__btn-save'
                    onClick={() => {
                        props.submitCb(value ? value : {})
                        props.handleShow()
                    }}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}