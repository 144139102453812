import React from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import './Pagination.css'

function Pagination(props) {
    return (
        <Container className='pagination-container'>
            <Row>
                <Col>
                    <div className='pagination d-flex justify-content-end align-items-center'>
                        <Form.Select className='pagination__select' onChange={props.changeLimit} >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                        </Form.Select>
                        <Button className='pagination__button' onClick={props.decreaseCurrentPage} >Prev</Button>
                        <div>{props.currentPage}/{props.totalPages}</div>
                        <Button className='pagination__button' onClick={props.increaseCurrentPage}>Next</Button>
                    </div>
                    
                </Col>
            </Row>
        </Container>
    )
}

export default Pagination;