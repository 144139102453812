import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import './MainTable.css'
import MainTableBody from "./MainTableBody";

//check if it's working


const MainTable = (props) => {
   
    const data = props.data;

    // console.log(data);
    
    let labelsArray = []
    for (let key in data[0]) {
        labelsArray.push(key);
    }
    // console.log(labelsArray);
    
    return (
        <Container className='users-table-wrapper'>
            <Row>
                <Col className='table-wrapping'>
                    <ListGroup className='user-table__labels-wrapping'>
                        <ListGroupItem className='user-table__labels d-flex'>
                            {labelsArray.map((value, index) => (
                                <div 
                                    className='labels__label text-primary'
                                    key={index}
                                >

                                    <Button
                                        variant='link'
                                        value={value}
                                        onClick={props.handleFilterSelect}
                                    >
                                        {value}
                                    </Button>

                                </div>
                            ))}
                            
                            {/*<div className='labels__label'></div>*/}
                        </ListGroupItem>
                    </ListGroup>
                    <MainTableBody
                        tableName={props.tableName}
                        data={props.data}
                        setDataUpdated={props.setDataUpdated}
                        handleShow={props.handleShow}
                        url={props.url}
                    />
                </Col>
            </Row>
            
        </Container>
        
    );
}

export default MainTable;