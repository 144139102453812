import { useState, useEffect } from "react";
import { pageTable } from "../api/tables"; 

export const usePageTable = (table, keys, page, limit, match) => {
    const [status, setStatus] = useState({ loading: true, error: undefined, result: { total: 0, data: [] }, dataUpdated: false })
    useEffect(() => {
        pageTable(table, keys, page, limit, match)
        .then(response => {
            if (response.errors) {
                setStatus({...status, loading: false, error: response.errors[0], dataUpdated: false})
            } else {
                setStatus({loading: false, error: undefined, result: response.data[`page${table}`], dataUpdated: false })
            }
        })
    }, [table, keys, page, limit, match, setStatus, pageTable, status.dataUpdated])
    return {...status, setDataUpdated: () => setStatus({...status, dataUpdated: true})}
}