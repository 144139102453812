import React, {useState} from 'react';
import {Form} from "react-bootstrap";

const AdditionalFieldsSingleCheckbox = (props) => {

    const [checked, setChecked] = useState(props.schema.includes(props.value));

    const handleClick = (event) => {
        props.changeSchemaArray(event);
        setChecked(!checked);
    }

    return (
        <Form.Check
            className='file-type__radio'
            label={props.label.toUpperCase()}
            value={props.value}
            type='checkbox'
            onChange={handleClick}
            checked={checked}
        />
    );
}

export default AdditionalFieldsSingleCheckbox;