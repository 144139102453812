import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {Link} from 'react-router-dom';
import "./SearchRow.css"

const SearchRow = (props) => {
    // const history = useHistory();
    // let {url} = useRouteMatch();
    
    return (
        <Container className='search-container'>
            <Row className='search-row'>
                <Col className='button-holder'>
                    <Link to='/'>
                        <Button>Home</Button>
                    </Link>
                    <Button
                        className='button-holder__single-button'
                        onClick={() => props.handleShow()}
                    >Add
                        {props.properName}
                    </Button>
                    <Button
                        className='button-holder__single-button'
                        onClick={() => props.handleImportShow()}
                    >
                        Import {props.properName}s
                    </Button>
                </Col>
                {/* <Col className='input-holder'>
                    <InputGroup>
                        <FormControl
                            className='input-holder__input-field'
                            placeholder='Search'
                            aria-label='Search'
                            aria-describedby='search-button'
                            onChange={props.handleSearchSubmit}
                        />
                    </InputGroup>
                </Col> */}
            </Row>
        </Container>
    );
}

export default SearchRow;