import React, { useState } from "react";
import './forms.css';
import { Form, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

const LoginForm = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState();

    const emailChange = (event) => {
        setEmail(event.target.value);
    };

    const passwordChange = (event) => {
        setPassword(event.target.value);
    };

    const user = {
        email: email,
        password: password,
        tenantId: 'd0772378-7c87-44f1-aa2a-0cc1c045a821'
    };


    async function handleSubmit(event) {
        event.preventDefault();
        var raw = JSON.stringify(user);
        var requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer'
        },
        body: raw,
        redirect: 'follow',
        };
        // window.location.origin +
        const response = await fetch( props.url +'/api/v1/auth/signin', requestOptions);
        const data = await response.json();
        if (data.status === 'success') {
            localStorage.setItem('token', data.data.tokens.access);
            localStorage.setItem('refresh_token', data.data.tokens.refresh);
            history.push('/crm');
            props.setLoggedIn(true);
            props.setLoggedUserData(data.data.user);
        } else if (data.status === 'error') {
            setErrorMessage(data.message);
        } else {
            console.log(data);
        }
    }

    return (
        <div>
            <Form className='form' onSubmit={handleSubmit}>
                <Form.Group className='form__line'>
                    <h1 className='form__line-title'>Sign in to CRM</h1>
                    <Form.Label className='line__name'>Email</Form.Label>
                    <Form.Control 
                        type='text' 
                        // value={user.email} 
                        placeholder='Enter email' 
                        className='line__input'
                        onChange={emailChange}
                    />
                </Form.Group>
                <Form.Group className='form__line'>
                    <Form.Label className='line__name'>Password</Form.Label>
                    <Form.Control 
                        type='password' 
                        // value={user.password} 
                        placeholder='********' 
                        className='line__input'
                        onChange={passwordChange}
                    />
                </Form.Group>
                {errorMessage ? (<div className='login__error-message'>{errorMessage}</div>) : ('')}
                <Button 
                    type='submit' 
                    variant='primary' 
                    className='form__button'
                >
                    Login
                </Button>
                <Link to='/'>
                    <Button 
                        variant='primary' 
                        className='form__button'
                    >
                        Back
                    </Button>
                </Link>
            </Form>
            
        </div>
    );
}

export default LoginForm;