import React, { useState } from "react";
import './forms.css';
import { Form, Button, } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';


const RegistryForm = (props) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const usernameChange = (event) => {
        setUsername(event.target.value);
    };
    const emailChange = (event) => {
        setEmail(event.target.value);
    };
    const passwordChange  = (event) => {
        setPassword(event.target.value);
    };

    const newUser = {
        email: email,
        password: password,
        tenantId: 'd0772378-7c87-44f1-aa2a-0cc1c045a821',
        username: username
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        var raw = JSON.stringify(newUser);

        var requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow'
        };

        fetch(props.url +'/api/v1/auth/signup', requestOptions)
        .then(response => response.status === 200 ? history.push('/login'): console.log(response.text()))
        .catch(error => console.log('error', error));
        
        // result => console.log(result)
    };

    return (
        <div>
            <Form 
                className='form'
                onSubmit={handleSubmit}
            >
                <Form.Group className='form__line'>
                    <h1 className='form__line-title'>Create account in CRM</h1>
                    <Form.Label className='line__name'>Your username</Form.Label>
                    <Form.Control 
                        type='text' 
                        placeholder='Enter username' 
                        className='line__input'
                        onChange={usernameChange}
                    ></Form.Control>
                </Form.Group>
                <Form.Group className='form__line'>
                    <Form.Label className='line__name'>Email</Form.Label>
                    <Form.Control 
                        type='email' 
                        placeholder='Enter email' 
                        className='line__input'
                        onChange={emailChange}
                    ></Form.Control>
                </Form.Group>
                <Form.Group className='form__line'>
                    <Form.Label className='line__name'>Password</Form.Label>
                    <Form.Control 
                        type='password' 
                        placeholder='********' 
                        className='line__input'
                        onChange={passwordChange}
                    ></Form.Control>
                </Form.Group>
                <Form.Group className='form__line line-checkbox'>
                    <Form.Check type='checkbox' label/>
                    <Form.Text className='line__checkbox text'> 
                        <a href='/register/terms'>Terms of use</a>
                    </Form.Text>
                </Form.Group>
                <Button 
                    type='submit' 
                    variant='primary' 
                    className='form__button'
                >
                    Register
                </Button>
                <Link to='/'>
                    <Button variant='primary' className='form__button'>Back</Button>
                </Link>
            </Form>
            

        </div>
    );
}

export default RegistryForm;