import React, { useMemo, useState, useEffect } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import "./ModularModal.css"
import { ModularInput } from "./ModularInput"
import { normalizeSchema } from "../../util";
import { createItem, updateItem, deleteItem } from "../../api/tables";
const ignoreColumns = ["tenantId", "ownerId", "groupId", "deleted", "modifiedAt", "createdAt"]



export const ModularModal = (props) => {
    const [value, setValue] = useState({}) 

    const schema = useMemo(() => normalizeSchema(props.tableMeta), [])
    useEffect(() => {
        setValue({})
    }, [props.show])
    const setKeyValue = (key, newVal) => setValue({...value, [key]: newVal })

    return (
        <Modal show={props.show} onHide={props.handleShow} centered className='modular-edit-modal'>
            <Modal.Header>
                <Modal.Title>{props.tableName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                   { Object.entries(schema).filter(([key]) => !ignoreColumns.includes(key)).map(([key, type]) => 
                        <ModularInput key={key} setKeyValue={setKeyValue} inputKey={key} type={type} val={props.data ? props.data[key] : undefined} />
                    
                    )}
                    <div className='d-flex form__action-btns'>
                        {/* <Link to='/manage-user-groups'>
                            <Button>Manage groups</Button>
                        </Link> */}
                        {
                            props.data ? 
                            <Button 
                                onClick={() => deleteItem(props.tableName, {id: props.data.id}).then(() => {
                                    props.setDataUpdated()
                                    props.handleShow()
                                })}
                            >Delete </Button> 
                            : <></>
                        }
                    </div>
                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className='form__btn-back' onClick={props.handleShow}>Cancel</Button>
                <Button 
                    variant="primary" 
                    className='form__btn-save'
                    onClick={() => {
                        props.data ? 
                        updateItem(props.tableName, value, { id: props.data.id })
                        .then(() => {
                            props.setDataUpdated()
                            props.handleShow()
                        })
                        :
                        createItem(props.tableName, value)
                        .then(() => {
                            props.setDataUpdated()
                            props.handleShow()
                        })
                    }}
                >
                    {props.data ? "Save changes" : "Create new"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}