import React, { useState } from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import ImportModalAdditionalFIelds from "./ImportModalAdditionalFIelds";
import ImportModalInputGroup from './ImportModalInputGroup';

const ImportModal = (props) => {

    const [schema, setSchema] = useState(props.tableMeta.config.defaultColumns.filter(key => key !== 'id'));

    const [openAddInputModal, setOpenAddInputModal] = useState(false);

    const [fileType, setFileType] = useState();

    const [file, setFile] = useState();

    const [formValid, setFormValid] = useState(false);

    const changeSchemaArray = (event) => {
        if (event.target.checked) {
            setSchema((prevState) => [...prevState, event.target.value])
        } else if (!event.target.checked) {
            setSchema((prevState) => prevState.filter(field => field !== event.target.value))
        }
    }

    const resetSchema = () => {
        setSchema(props.tableMeta.config.defaultColumns.filter(key => key !== 'id'))
    }

    const handleAddInputOpen = () => {
        setOpenAddInputModal(!openAddInputModal);
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const handleFileSelect = (event) => {
          setFileType(event.target.value);
    };

    const resetFile = () => {
        setFile(undefined);
        setFileType(undefined);
        props.handleImportShow();
    };

    const handleImport = () => {

        const params = new URLSearchParams();

        const inputs = document.querySelectorAll('input');

        inputs.forEach(element => {
            if (element.name !== '' && element.name !== 'fileType'){
                params.append(element.name, element.value)
            }
        });

        let myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

        let formdata = new FormData();
        formdata.append(file.name, file);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(props.url +"/api/v1/tenant/import/" + props.tableName  +"/"+ fileType +"?" + params.toString(), requestOptions)
            .then(result => result.json())
            .then(r => {
                if (r.status === 'success') {
                    props.handleImportShow();
                    props.setDataUpdated();
                } else {
                    window.alert(r.error)
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            setFormValid(false);
        } else {
            setFormValid(true);
            event.preventDefault();
            handleImport();
        }

    }

    return (
        <>
            <Modal show={props.showImportModal} onHide={resetFile} centered className='modular-edit-modal'>
                <Modal.Header>
                    <Modal.Title>Import {props.tableName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='create-user-form-body' onSubmit={handleSubmit} validated={formValid}>
                        {
                            // Object.entries(schema).filter(([key]) => !ignoreColumns.includes(key))
                            schema.map((key, index) => <ImportModalInputGroup key={index} value={key}/>
                        )}
                        <div className='import-modal__additional-fields-button d-flex justify-content-around'>
                            <Button
                                onClick={handleAddInputOpen}
                            >
                                Add additional fields
                            </Button>
                            <Button
                                variant='danger'
                                onClick={resetSchema}
                            >
                                Remove all additional fields
                            </Button>
                        </div>
                        <Form.Group>
                            <Form.Label>Select file type</Form.Label>
                            <Form.Check
                                className='file-type__radio'
                                label="CSV"
                                name="fileType"
                                value='csv'
                                type='radio'
                                required
                                onClick={handleFileSelect}
                            />
                            <Form.Check
                                className='file-type__radio'
                                label="TSV"
                                name="fileType"
                                value='tsv'
                                type='radio'
                                required
                                onClick={handleFileSelect}
                            />
                        </Form.Group>
                        <Form.Group controlId="formFileLg" className="mb-3">
                            <Form.Label>Select your CSV file</Form.Label>
                            <Form.Control
                                required
                                type="file"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                        <div className='d-flex justify-content-end'>
                            <Button
                                variant="secondary"
                                className='form__btn-back'
                                onClick={resetFile}
                            >
                                Back
                            </Button>
                            <Button
                                variant="primary"
                                className='form__btn-save'
                                type='submit'
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <ImportModalAdditionalFIelds
                schema={schema}
                handleAddInputOpen={handleAddInputOpen}
                openAddInputModal={openAddInputModal}
                changeSchemaArray={changeSchemaArray}
                allColumns={props.tableMeta.config.orderOfColumns}
                defaultColumns={props.tableMeta.config.defaultColumns}
            />
        </>
    );
}

export default ImportModal;


