import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';

const ImportModalInputGroup = (props) => {

    const key = props.value;

    const [inputAmount, setInputAmount] = useState([{key: key, index:0}]);

    const increaseInputAmount = () => {
        setInputAmount(prevState => [...prevState, {key: key, index: prevState.length}]);
    }

    const decreaseInputAmount = () => {
        setInputAmount(prevState => prevState.filter(object => object.index !== prevState.length - 1));
    }

    return (
        <Form.Group className='create-user-form-body__input-group mb-3' controlId="formBasicEmail" key={key}>
            <Form.Label
                className='input-group__field-title'
            >
                {'Name of "' + key +'" column'}
            </Form.Label>
            { inputAmount.map((input) =>
                <Form.Control
                    key={input.index}
                    name={key}
                    className='input-group__input-field'
                    type='text'
                    required
                    placeholder={key.toUpperCase()}
                />
            )}

            <div className='d-flex'>
                <Button
                    variant='success'
                    onClick={increaseInputAmount}
                >
                    Add input
                </Button>
                {
                    inputAmount.length > 1 &&
                    <Button
                        variant='danger'
                        onClick={decreaseInputAmount}
                    >
                        Remove input
                    </Button>
                }

            </div>
        </Form.Group>
    )
}

export default ImportModalInputGroup;