import React  from "react";
import './MainTable.css'
import { Button, ListGroupItem } from 'react-bootstrap';

 
const MainTableRow = (props) => {

    let data = []
    for (let key in props.data) {
    data.push(props.data[key]);
    }


    
    return (
        <ListGroupItem className='d-flex single-table-row'>
            {data.map((value, index) => (
                    <div className='single-user__id single-user__block' key={index}>
                        {   
                            (typeof value === 'object' && value !== null && Array.isArray(value) === false) 
                            ?
                            (Object.keys(value).map((schedule, index) => <span key={index}>{schedule}</span>)) 
                            : 
                            (
                                (Array.isArray(value) === true) 
                                ? 
                                (
                                    value.map((role, index) => (<span key={index} >{role.name}</span>))
                                )  
                                : 
                                (
                                    (typeof value === "boolean")
                                        ?
                                        (value.toString())
                                        :
                                        (value)
                                )
                            )
                        }
                    </div>) 
            )}
            <div className=' single-user__block single-user__button-block'>
                <Button 
                    className='single-user__edit-button'
                    onClick={() => props.handleShow(props.data) }
                >
                    Edit
                </Button>
            </div>
            {/* <EditModal
                tableName={props.tableName}
                show={showModal} 
                handleShow={handleShow}
                data={props.data}
                setDataUpdated={props.setDataUpdated}
                url={props.url}
            /> */}
            
        </ListGroupItem>
    );
}

export default MainTableRow;