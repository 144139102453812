import React from "react";
import { Link } from 'react-router-dom';
// import user from '../../assets/user.svg';
import './TopPanel.css';

function TopPanel(props) {
    return (
        <div className='navbar-holder d-flex align-items-center align-middle justify-content-between'>
            <div className='navbar-header'>
                <Link to='/' className='header__link-logo'>
                    Inspectron CRM
                </Link>
            </div>
            {/* {props.loggedIn ? 
                (<ul className='nav-menu list-unstyled d-flex flex-md-row align-items-md-center pull-right'>
                    <li>
                        <img src={user} alt='UserIcon' className='top-panel-user-icon'/>
                    </li>
                </ul>)
                :
                (<></>)
            } */}
        </div>
    )
}

export default TopPanel;