import React from "react";
import {Switch, Route, useRouteMatch} from "react-router-dom";
import GenericTable from "../GenericTable/GenericTable";
import CreateModularForm from "../GenericTable/CreateModularForm/CreateModularForm";

function CrmTablePage(props) {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <GenericTable
                    tableName={props.tableName}
                    tableColumns={props.tableColumns}
                    url={props.url}
                    tableMeta={props.tableMeta}
                />
            </Route>
            <Route path={`${path}/add-new`}>
                <CreateModularForm
                    tableColumns={props.tableColumns}
                    url={props.url}
                    tableName={props.tableName}
                />
            </Route>
        </Switch>
    )
}

export default CrmTablePage;