import React, {useState, useMemo} from "react";
import MainTable from "../MainTable/MainTable";
import SearchRow from "../SearchRow/SearchRow";
import Pagination from "../Pagination/Pagination";
import pluralize from 'pluralize';
import { ModularModal } from "../ModularModal/ModularModal";
import { usePageTable } from "../../hooks/tables";
import ImportModal from "./ImportModal/ImportModal";

const GenericTable = (props) => {

    //test commit

    
    const [showModal, setShowModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [targetItem, setTargetItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(5);


    const nonEditedName = props.tableName;

    const handleShow = (target) => {
        setTargetItem(target)
        setShowModal(!showModal)
    };

    const handleImportShow = () => {
        setShowImportModal(!showImportModal);
    };


    function getProperName(nonEditedName) {
        let name = pluralize.singular((nonEditedName[0].toUpperCase() + nonEditedName.slice(1).replace(/[-_][a-z]/, a => {
            return a[1].toUpperCase();
        })));
        // pluralize.singular((nonEditedName[0].toUpperCase() + nonEditedName.slice(1).replace(/[-_][a-z]/, a => {
        //     return a[1].toUpperCase();})));
        return name;
    }

    const tableName = useMemo(()=> {
        return getProperName(nonEditedName);
    }, [nonEditedName]);


    const columnsToGet = props.tableColumns;
    const [queryColumns] = useState(Object.keys(columnsToGet))

    const { result, error, loading, setDataUpdated } = usePageTable(tableName, queryColumns, currentPage, limit)


    const totalPages = result ? Math.ceil(result.total/limit) : 0;

    function changeLimit(event) {
        setLimit(event.target.value);
        setCurrentPage(1);
    }

    function increaseCurrentPage() {
        if (currentPage < totalPages) {
            setCurrentPage(prevState => {
                return prevState + 1;
            });
        }
    }

    function decreaseCurrentPage() {
        if (currentPage > 1) {
            setCurrentPage(prevState => {
                return prevState - 1;
            });
        }
    }

    if (error) {
      return (<div className='page-table__error'>Error: {error.message}</div>);
    } else if (loading) {
      return (<div>Loading...</div>);
    } else {
      return (
        <>
            <SearchRow
                tableName={props.tableName}
                properName={tableName}
                handleShow={handleShow}
                handleImportShow={handleImportShow}
                showImportModal={showImportModal}
            />
            <MainTable
                tableName={tableName}
                data={result.data}
                setDataUpdated={setDataUpdated}
                url={props.url}
                handleShow={handleShow}
            />
            <Pagination
                currentPage = {currentPage}
                totalPages = {totalPages}
                increaseCurrentPage={increaseCurrentPage}
                decreaseCurrentPage={decreaseCurrentPage}
                changeLimit={changeLimit}
            />
            <ModularModal
                tableName={tableName}
                show={showModal} 
                handleShow={handleShow}
                data={targetItem}
                setDataUpdated={setDataUpdated}
                url={props.url}
                tableMeta={props.tableMeta}
                submitCb={(value) => console.log(value)}
                deleteCb={(value) => console.log(value)}
            />
            <ImportModal
                tableName={props.tableName}
                showImportModal={showImportModal}
                tableMeta={props.tableMeta}
                handleImportShow={handleImportShow}
                url={props.url}
                setDataUpdated={setDataUpdated}
            />
        </>
      );
    }
}

export default GenericTable;