import {apiUrl, graphQLUrl} from "../constants/url";

const refreshJWT = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('refresh_token'));
    const requestOptions = {
        method: 'POST',
        headers,
        redirect: 'follow'
    };

    return fetch(apiUrl + '/auth/refresh', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status === 'success') {
                localStorage.setItem('token', result.data.tokens.access);
                console.log('true')
                return true
            } else {
                console.log('false')
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.reload();
                return false
            }
        })
        .catch(error => console.log('error', error));
}

export const sendRequest = (url, body) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        redirect: 'follow'
    };

    let retry = false;

    return fetch(url, requestOptions)
        .then(r => r.json())
        .then(response => {
            if (response.errors) {
                if (response.errors[0].message === 'User not Authenticated') {
                    retry =  refreshJWT();
                    if (retry === false) {
                       sendRequest(url, body);
                    } else {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshToken');
                        window.location.reload();
                    }
                } else {
                    window.alert(response.errors[0].message)
                }
            } else {
                return response
            }
        })
        .catch(error => window.alert('Error:', error))
}

export const gqlFormat = data => {
    switch (typeof data) {
        case "number": {
            return data
        }
        case "string": {
            return `"${data}"`
        }
        case "boolean": {
            return data ? "true" : 'false'
        }
        case "object": {
            if (Array.isArray(data)) {
                return "[" + data.map(d => gqlFormat(d)).join(",") + "]"
            } else {
                return `{ ${Object.entries(data).map(([key, val]) => `${key}: ${gqlFormat(val)}` ).join(",")} }`
            }
        }
        default: {
            return ""
        }
    }
}

export const graphQLRequest = (body, variables = {}) => {
    return sendRequest(graphQLUrl, { query: body, variables })
}

export const apiRequest = () => {

}