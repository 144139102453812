import React, { useState, useEffect } from "react"
import "./ModularModal.css";
import { Form, Button } from "react-bootstrap";
import plus from '../../assets/plus-solid.svg';
import minus from '../../assets/times-solid.svg';
import { MapModal } from "./MapModal";

const disabledColumns = ["id", "tenantId", "createdAt", "modifiedAt", "ownerId"]

export const ModularInput = (props) => {
    let [value, setValue] = useState(props.val)
    useEffect(() => {
        props.setKeyValue(props.inputKey, value)
    }, [value])

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);
    return (
        <Form.Group className='create-user-form-body__input-group mb-3' controlId="formBasicEmail" key={props.inputKey}>
            <Form.Label className='input-group__field-title'>
                {props.inputKey}
            </Form.Label>
                        
            {(() => {
                switch(props.type.type.toLowerCase()) {
                    
                    case "string": {
                        return (<Form.Control
                            disabled={disabledColumns.includes(props.inputKey)}
                            name={props.inputKey}
                            className='input-group__input-field'
                            type='text'
                            defaultValue={props.val}
                            placeholder={'Enter ' + props.inputKey}
                            onChange={e => setValue(e.target.value)}
                        />)
                    }
                    case "integer":
                    case "int": {
                        return (<Form.Control
                            disabled={disabledColumns.includes(props.inputKey)}
                            name={props.inputKey}
                            className='form__input form__input-number form-control'
                            type='number'
                            defaultValue={props.val}
                            placeholder={'Enter ' + props.inputKey}
                            onChange={e => setValue(+e.target.value)}
                        />)
                    }
                    case "boolean": {
                        value = !!value;
                        return (<Form.Check
                            checked={value}
                            className='form__input form__input-checkbox'
                            name={props.inputKey}
                            onChange={() => setValue(!value)}
                            value={value}
                        />)
                    }
                    case "date": {
                        return (<Form.Control
                            disabled={disabledColumns.includes(props.inputKey)}
                            name={props.inputKey}
                            className='input-group__input-field'
                            type='Date'
                            defaultValue={new Date(props.val)}
                            placeholder={'Enter ' + props.inputKey}
                            onChange={e => setValue(e.target.value)}
                        />)
                    }
                    case "enum": {
                        return (<Form.Select
                            name={props.inputKey}
                        >
                            {props.type.values.map(v => <option selected={v === props.val} value={v}>{v}</option>)}
                        </Form.Select>)
                    }
                    case "time": {
                        return (<Form.Control
                            disabled={disabledColumns.includes(props.inputKey)}
                            name={props.inputKey}
                            className='input-group__input-field'
                            type='time'
                            defaultValue={props.val}
                            placeholder={'Enter ' + props.inputKey}
                            onChange={e => setValue(e.target.value +":00")}
                        />)
                    }
                    case "array": {
                        value = value === undefined ? [] : value
                        return (<>
                            {value.map((v, i) =>
                                <div key={i} style={({ display: "flex", padding: "0.3em", alignItems: 'center', marginBottom: '8px'})}>
                                    <Form.Control
                                        className='input-group__input-field'
                                        type='text'
                                        name={i}
                                        value={value[i]}
                                        onChange={e => {
                                            setValue(value.slice(0,i).concat(e.target.value, value.slice(i+1)))
                                        }}
                                    />
                                    <Button
                                        className='modal-minus-btn'
                                        onClick={() => {
                                            setValue(value.slice(0,i).concat(value.slice(i+1)))
                                        }}
                                    >
                                        <img
                                            className='modal-minus-icon'
                                            src={minus}
                                            alt=""/>
                                    </Button>
                                </div>)
                            }
                            <Button
                                className='modal-plus-button'
                                onClick={() => setValue(value.concat(""))}
                            >
                                <img
                                    className='modal-plus-icon'
                                    src={plus}
                                    alt=""
                                />
                            </Button>
                        </>)
                    }


                    case "json": {
                        value = value ? value : {}; 
                        return (
                            <div className="form-json-columns">
                                { Object.entries(props.type.fields).map(([key, keyType]) => <ModularInput
                                    key={key} setKeyValue={(k, v) => setValue({...value, [k]: v })} inputKey={key}
                                    type={keyType} value={value[key]}
                                />)}
                            </div>
                        )
                    }

                    case "map": {
                        return <>
                            <MapModal 
                                submitCb={(v) => setValue(v)}
                                deleteCb={() => setValue(undefined)}
                                show={showModal} 
                                handleShow={toggleModal}
                                keyType={props.type.keyType}
                                inputKey={props.inputKey}
                                val={value}
                            />
                            <Button
                                className='modal-plus-button'
                                onClick={toggleModal}
                            >Edit</Button>
                        </>
                    }

                }
            })()}
        </Form.Group>
    )
} 