import React from "react";
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// import GenericTable from "../GenericTable/GenericTable";
import CrmTablePage from "../CrmTablePage/CrmTablePage";

function CrmMainPage(props) {

    let { path } = useRouteMatch();

    // console.log(props.tableMeta[0].table);

    return (
        <Switch>
            <Route exact path={path}>
                <div>Here will be dashboard</div>
            </Route>

            {
                props.tableMeta
                &&
                props.tableMeta.map((table) => {
                    return (
                        <Route key={table.table} path={''+ path + '/' + table.table + ''}>
                            <CrmTablePage tableMeta={table} tableName={table.table} tableColumns={table.view} url={props.url}/>
                        </Route>
                    )
                })
            }
        </Switch>
    )
}

export default CrmMainPage;