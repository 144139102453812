import React from "react";
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import pluralize from "pluralize";

function CreateModularForm(props) {

    const history = useHistory();

    const tableField = Object.entries(props.tableColumns);
    
    // console.log(props.tableColumns);

    function goBack () {
        history.goBack();
    }

    function getProperName(unformattedName) {
        let name = pluralize.singular((unformattedName[0].toUpperCase() + unformattedName.slice(1).replace(/[-_][a-z]/, a => {
            return "" + a[1];
        })));
        // pluralize.singular((nonEditedName[0].toUpperCase() + nonEditedName.slice(1).replace(/[-_][a-z]/, a => {
        //     return a[1].toUpperCase();})));
        return name;
    }
    const queryTableName = getProperName(props.tableName);
    console.log(queryTableName);

    //TODO: add input type definition similar to user edit modal
    //TODO: check if I pass data so I can define data type
    //TODO: add input types

    function CreateNewTableRecord() {

        // get data from inputs

        const inputs = document.querySelectorAll('input');
        let newDataObj = {};
        inputs.forEach(function(element) {
            newDataObj = { ...newDataObj, [element.name]: element.value};
        });

        // this transforms data into format suitable for query

        let formattedQueryData = []
        for (let key in newDataObj) {
            if (props.tableColumns[key] === 'String') {
                formattedQueryData.push(' ' + key + ': "' + newDataObj[key] +'"');
            } else {
                formattedQueryData.push(' ' + key + ': ' + newDataObj[key] +'');
            }
        }
        console.log(formattedQueryData);

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

        let graphql = JSON.stringify({
            query: "mutation {    create"+
                queryTableName
                +"(data: { "+ formattedQueryData +" }) }",
            variables: {}
        })
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };

        fetch(props.url + "/graphql", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    return (
        <Form className='create-user-form-body'>
            {tableField.map(([key, value]) =>
                (key !== 'id') && (key !== 'createdAt') && (key !== 'modifiedAt')
                && (key !== 'deleted') && (key !== 'groupId') && (key !== 'ownerId')
                && (key !== 'reservable')
                    &&
                    <Form.Group className='create-user-form-body__input-group mb-3' controlId="formBasicEmail" key={key}>
                        <Form.Label
                            className='input-group__field-title'
                        >
                            {key}
                        </Form.Label>
                        {value === 'String'
                            &&
                            <Form.Control
                                name={key}
                                className='input-group__input-field'
                                type='text'

                                placeholder={'Enter ' + key}
                            />
                        }
                        {value === 'Int'
                        &&
                        <Form.Control
                            name={key}
                            className='input-group__input-field'
                            type='number'
                            placeholder={'Enter ' + key}
                        />
                        }
                        {value === 'Float'
                        &&
                        <Form.Control
                            name={key}
                            className='input-group__input-field'
                            type='number'
                            step='0.01'
                            min='0'
                            placeholder={'Enter ' + key}
                        />
                        }
                        {value === 'Date'
                        &&
                        <Form.Control
                            name={key}
                            className='input-group__input-field'
                            type='date'

                            placeholder={'Enter ' + key}
                        />
                        }
                        {/*{value === 'Boolean'*/}
                        {/*&&*/}
                        {/*<Form.Control*/}
                        {/*    name={key}*/}
                        {/*    className='input-group__input-field'*/}
                        {/*    type='checkbox'*/}
                        {/*/>*/}
                        {/*}*/}
                    </Form.Group>
            )} 
            <div className='d-flex'>
                <Button
                    variant="primary"
                    onClick={CreateNewTableRecord}
                    className='create-user-form-body__button'>
                    Submit
                </Button>
                <Button 
                    variant="primary" 
                    className='create-user-form-body__back-btn'
                    onClick={goBack}
                >
                    Back
                </Button>
            </div> 
        </Form>
    )
}

export default CreateModularForm;