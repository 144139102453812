import React from 'react';
import {Modal, Button, Form} from "react-bootstrap";
import AdditionalFieldsSingleCheckbox from './AdditionalFieldsSingleCheckbox'

const ImportModalAdditionalFIelds = (props) => {

    const ignoreInputs = ["tenantId", "ownerId", "groupId", "deleted", "modifiedAt", "createdAt", "String", "Boolean", "Int"]

    const filtered = props.allColumns.filter(key => !ignoreInputs.includes(key));

    return (
        <Modal show={props.openAddInputModal} onHide={props.handleAddInputOpen} centered>
            <Modal.Header closeButton>
                <Modal.Title>Select additional fields</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                {filtered.map((column, index)=>
                    <AdditionalFieldsSingleCheckbox
                        key={index}
                        schema={props.schema}
                        label={column.toUpperCase()}
                        value={column}
                        changeSchemaArray={props.changeSchemaArray}
                    />
                )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleAddInputOpen}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ImportModalAdditionalFIelds;