import { graphQLRequest, gqlFormat } from "./util"

export const getTableMeta = () => {
    return graphQLRequest("{ tableMeta }")
        .then(result => {
        if (result.errors) {
            window.alert(result.errors[0].message)
        } else {
            return result
        }
    })
}

export const pageTable = (table, keys, page = 1, limit = 20, match) => {
    return graphQLRequest(`{
        page${table}(page: ${page}, limit: ${limit}${match ? ` , match: ${gqlFormat(match)}`:""}) {
            total,
            data {
                ${keys.join(",\n")}
            }
        }
    }`
    )
    .then(result => {
        if (result.errors) {
            window.alert(result.errors[0].message)
        } else {
            return result
        }
    })
}

export const createItem = (table, data) => {
    return graphQLRequest(`mutation {
        create${table} (
            data: ${gqlFormat(data)}
        )
    }`
    )
    .then(result => {
        if (result.errors) {
            window.alert(result.errors[0].message)
        } else {
            return result
        }
    })
}

export const updateItem = (table, data, match) => {
    return graphQLRequest(`mutation {
        update${table} (
            data: ${gqlFormat(data)}${match ? ` , match: ${gqlFormat(match)}` : ""}
        )
    }`
    )
    .then(result => {
        if (result.errors) {
            window.alert(result.errors[0].message)
        } else {
            return result
        }
    })
}

export const deleteItem = (table, match) => {
    return graphQLRequest(`mutation {
        delete${table} ${match ? `(match: ${gqlFormat(match)})` : ""}
    }`
    )
    .then(result => {
        if (result.errors) {
            window.alert(result.errors[0].message)
        } else {
            return result
        }
    })
}