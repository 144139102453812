export const normalizeSchema = (tableMeta) => {
    let schema = {}
    for(let key in tableMeta.view) {
        if (tableMeta.customTypes && tableMeta.customTypes[key]) {
            schema[key] = tableMeta.customTypes[key]
        } else {
            schema[key] = { type: tableMeta.view[key] }
        }
    }
    console.log({tableMeta, schema})
    return schema;
}